import { render, staticRenderFns } from "./FacultyConnections.vue?vue&type=template&id=48fb0c92&scoped=true"
import script from "./FacultyConnections.vue?vue&type=script&lang=js"
export * from "./FacultyConnections.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fb0c92",
  null
  
)

export default component.exports
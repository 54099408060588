var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-tabs',{attrs:{"type":"border-card"}},[_c('el-tab-pane',{attrs:{"label":`${_vm.$t('actions.edit')} ${_vm.$tc('models.faculty')}`}},[_c('ui-card',{staticClass:"mb-8",attrs:{"heading":`${_vm.$t('actions.administrate')} ${_vm.$tc('models.faculty')}`}},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('el-select',{attrs:{"placeholder":"Velg bygg"},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}},_vm._l((_vm.companies),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),(_vm.selectedCompany)?_c('ui-link',{attrs:{"type":"text"},on:{"click":function($event){_vm.newFacultyModal = true}}},[_vm._v(" "+_vm._s(`${_vm.$t("actions.add")} ${_vm.$t("models.faculty")}`)+" ")]):_vm._e()],1),(_vm.selectedCompany)?_c('faculty-connections',{staticClass:"mt-4",attrs:{"faculties":_vm.faculties,"loading":_vm.facultiesLoading},on:{"onUpdate":_vm.handleUpdateFaculty,"onDelete":_vm.handleDeleteFaculty}}):_vm._e()],1),(_vm.selectedCompany)?_c('ui-card',{staticClass:"mb-8",attrs:{"heading":`${_vm.$t('actions.administrate')} ${_vm.$tc(
          'models.faculty'
        )} ${_vm.$tc('models.departments')}`}},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('el-select',{attrs:{"placeholder":"Velg fakultet"},model:{value:(_vm.selectedFaculty),callback:function ($$v) {_vm.selectedFaculty=$$v},expression:"selectedFaculty"}},_vm._l((_vm.faculties),function(item){return _c('el-option',{key:item.facultyId,attrs:{"label":item.facultyName,"value":item.facultyId}})}),1),_c('ui-link',{attrs:{"type":"text"},on:{"click":function($event){_vm.newFacultyDepartmentModal = true}}},[_vm._v(" "+_vm._s(`${_vm.$t("actions.add")} ${_vm.$tc("models.department", 1)}`)+" ")])],1),(_vm.selectedFaculty)?_c('faculty-department-connections',{staticClass:"mt-4",attrs:{"departments":_vm.departments,"loading":_vm.departmentsLoading},on:{"onDelete":_vm.handleDeleteFacultyDepartment}}):_vm._e()],1):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":_vm.$t('misc.student_lookup')}},[_c('student-lookup')],1),_c('el-tab-pane',{attrs:{"label":_vm.$tc('links.registration_links', 2)}},[_c('ui-card',{staticClass:"mb-8",attrs:{"heading":_vm.$tc('links.registration_links', 2)}},[(_vm.registrationLinks)?_c('ui-list',{attrs:{"disableXScroll":true,"headings":[
            _vm.$t('fields.name'),
            _vm.$t('fields.reference'),
            _vm.$t('fields.expires'),
            _vm.$t('fields.actions'),
            // ''
          ],"items":_vm.registrationLinks},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.name}}),_c('ui-list-data',{attrs:{"text":props.item.reference}}),(
                props.item.expires &&
                new Date(props.item.expires).getTime() < new Date().getTime()
              )?_c('ui-list-data',{staticStyle:{"color":"red"},attrs:{"text":new Date(props.item.expires).toLocaleDateString('en-US')}}):_c('ui-list-data',{attrs:{"text":props.item.expires
                  ? new Date(props.item.expires).toLocaleDateString('en-US')
                  : '-'}}),_c('ui-list-data',{attrs:{"small":""}},[_c('ui-link',{attrs:{"type":"danger","uppercase":"","size":"small"},on:{"click":function($event){return _vm.handleDeleteRegistraionLink(props.item.reference)}}},[_vm._v(_vm._s(_vm.$t("actions.delete")))]),_c('ui-link',{staticClass:"ml-2",attrs:{"type":"success","uppercase":"","size":"small"},on:{"click":function($event){return _vm.handleEditRegistraionLink(props.item)}}},[_vm._v(_vm._s(_vm.$t("actions.edit")))])],1)]}}],null,false,1066664942)}):_vm._e()],1)],1)],1),(_vm.selectedCompany)?_c('create-faculty-modal',{attrs:{"show":_vm.newFacultyModal,"text":`${_vm.$t('actions.add')} ${_vm.$t('models.faculty')}`,"confirmText":_vm.$t('actions.add'),"company":_vm.selectedCompany},on:{"confirm":_vm.handleNewFaculty,"cancel":function($event){_vm.newFacultyModal = false}}}):_vm._e(),_c('add-faculty-department-modal',{attrs:{"show":_vm.newFacultyDepartmentModal,"text":`${_vm.$t('actions.add')} ${_vm.$t('models.faculty')}`,"confirmText":_vm.$t('actions.add'),"faculty":_vm.selectedFacultyObject,"optionalFaculties":_vm.faculties},on:{"confirm":_vm.handleNewFacultyDepartment,"cancel":function($event){_vm.newFacultyDepartmentModal = false}}}),_c('ui-modal',{attrs:{"show":_vm.showDeleteModal,"text":_vm.$t('links.actions.confirm_delete'),"info":_vm.$t('links.info.delete_link_info'),"confirmText":_vm.$t('actions.delete')},on:{"confirm":function($event){return _vm.deleteRegistrationLink()},"cancel":function($event){_vm.showDeleteModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <transition name="fade">
    <div v-if="show" class="modal-container">
      <div class="w-full max-w-xl">
        <div class="bg-white rounded overflow-hidden">
          <!-- Heading -->
          <div class="p-4 md:p-6 font-bold uppercase">
            Legg til nytt fakultet
          </div>

          <!-- Main Content -->
          <div class="px-4 pb-4 md:px-6">
            <div class="mt-4">
              <el-form ref="form" label-width="120px" class="mt-2">
                <el-form-item label="Bygning">
                  <el-select v-model="selectedBuilding" placeholder="Select building">
                    <el-option
                        v-for="item in buildings"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Navn på fakultet">
                  <el-input v-model="facultyName"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <!-- Footer -->
          <div class="p-4 md:p-6 bg-gray-100">
            <div class="flex justify-end">
              <ui-link type="text" class="ml-4" @click="handleCancel">
                {{cancelText}}
              </ui-link>
              <ui-link type="success" @click="handleConfirm">
                {{confirmText}}
              </ui-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import UiLink from '@/components/ui/UiLink';
import { getCompanyBuildings } from '@/app/companies/api';

export default {
  name: "CreateFacultyModal",

  components: {
    UiLink,
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    company: {
      type: Number,
      required: true
    },
    confirmText: {
      type: String,
      default: function() {
        return this.$t('actions.confirm');
      }
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('actions.cancel');
      }
    }
  },

  data() {
    return {
      // Data
      buildings: [],

      // User input
      selectedBuilding: null,
      facultyName: "",
    }
  },

  watch: {
    company: async function() {
      this.buildings = await getCompanyBuildings(this.company);
    }
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm', {
        buildingId: this.selectedBuilding,
        name: this.facultyName
      });
      this.selectedBuilding = null;
      this.facultyName = "";
    },

    handleCancel() {
      this.$emit('cancel');
      this.selectedBuilding = null;
      this.facultyName = "";
    },
  },

  async mounted() {
    this.buildings = await getCompanyBuildings(this.company);
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<template>
  <div>
    <ui-list v-loading="loading"
             :headings="[this.$tc('models.department', 1), '']"
             :items="departments"
             :sort="[
        { prop: 'building', prop2: 'name', type: 'level2' },
      ]"
    >
      <template v-slot:default="props">
        <ui-list-data :text="props.item.departmentName"></ui-list-data>

        <ui-list-data small>
          <ui-link type="danger" uppercase size="small" @click="handleOpenDeleteFacultyDepartmentModal(props.item)">{{$t('actions.delete_connection')}}</ui-link>
        </ui-list-data>
      </template>
    </ui-list>

    <!-- Modal displaying warning about deleting faculty -->
    <ui-modal
        :show="showDeleteFacultyDepartmentModal"
        :text="`${$t('confirm.delete.facultyDepartment')}`"
        @confirm="handleDeleteFacultyDepartment"
        @cancel="handleCloseDeleteFacultyDepartmentModal"/>
  </div>
</template>


<script>
import UiLink from "../../../../components/ui/UiLink";
import UiList from "../../../../components/ui/UiList";
import UiModal from "../../../../components/ui/UiModal";
import UiListData from "../../../../components/ui/UiListData";
import {deleteFacultyDepartment} from "../../api";

export default {
  name: "FacultyDepartmentConnections",
  components: {
    UiLink,
    UiList,
    UiModal,
    UiListData,
  },

  props: {
    departments: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
    }
  },

  data() {
    return {
      showDeleteFacultyDepartmentModal: false,
      markedDelete: null,
    }
  },

  methods: {

    // Delete faculty
    handleOpenDeleteFacultyDepartmentModal(faculty) {
      this.markedDelete = faculty;
      this.showDeleteFacultyDepartmentModal = true;
    },

    handleCloseDeleteFacultyDepartmentModal() {
      this.markedDelete = null;
      this.showDeleteFacultyDepartmentModal = false;
    },

    async handleDeleteFacultyDepartment() {
      const facultyDepartmentToDeleteId = this.markedDelete.facultyDepartmentId;
      await deleteFacultyDepartment(facultyDepartmentToDeleteId);
      this.showDeleteFacultyDepartmentModal = false;
      this.$emit('onDelete', facultyDepartmentToDeleteId);
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <el-tabs type="border-card">
      <!-- Edit user -->
      <el-tab-pane :label="`${$t('actions.edit')} ${$tc('models.faculty')}`">
        <!-- Manage faculties in a company -->
        <ui-card
          :heading="`${$t('actions.administrate')} ${$tc('models.faculty')}`"
          class="mb-8"
        >
          <div class="flex flex-row justify-between">
            <!-- Company picker to manage faculties for -->
            <el-select v-model="selectedCompany" placeholder="Velg bygg">
              <el-option
                v-for="item in companies"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>

            <!-- Button to add new faculty -->
            <ui-link
              v-if="selectedCompany"
              type="text"
              @click="newFacultyModal = true"
            >
              {{ `${$t("actions.add")} ${$t("models.faculty")}` }}
            </ui-link>
          </div>

          <!-- Table containing the faculties for the selected company -->
          <faculty-connections
            v-if="selectedCompany"
            :faculties="faculties"
            :loading="facultiesLoading"
            class="mt-4"
            @onUpdate="handleUpdateFaculty"
            @onDelete="handleDeleteFaculty"
          />
        </ui-card>

        <!-- Manage faculty departments for chosen faculty -->
        <ui-card
          v-if="selectedCompany"
          :heading="`${$t('actions.administrate')} ${$tc(
            'models.faculty'
          )} ${$tc('models.departments')}`"
          class="mb-8"
        >
          <div class="flex flex-row justify-between">
            <!-- Company picker to manage faculties for -->
            <el-select v-model="selectedFaculty" placeholder="Velg fakultet">
              <el-option
                v-for="item in faculties"
                :key="item.facultyId"
                :label="item.facultyName"
                :value="item.facultyId"
              >
              </el-option>
            </el-select>

            <!-- Button to add new faculty department -->
            <ui-link type="text" @click="newFacultyDepartmentModal = true">
              {{ `${$t("actions.add")} ${$tc("models.department", 1)}` }}
            </ui-link>
          </div>

          <!-- Table containing the faculties for the selected company -->
          <faculty-department-connections
            v-if="selectedFaculty"
            :departments="departments"
            :loading="departmentsLoading"
            class="mt-4"
            @onDelete="handleDeleteFacultyDepartment"
          />
        </ui-card>
      </el-tab-pane>

      <el-tab-pane :label="$t('misc.student_lookup')">
        <student-lookup />
      </el-tab-pane>

      <el-tab-pane :label="$tc('links.registration_links', 2)">
        <ui-card :heading="$tc('links.registration_links', 2)" class="mb-8">
          <ui-list
            v-if="registrationLinks"
            :disableXScroll="true"
            :headings="[
              $t('fields.name'),
              $t('fields.reference'),
              $t('fields.expires'),
              $t('fields.actions'),
              // ''
            ]"
            :items="registrationLinks"
          >
            <template v-slot:default="props">
              <ui-list-data :text="props.item.name" />
              <ui-list-data :text="props.item.reference" />
              <ui-list-data
                v-if="
                  props.item.expires &&
                  new Date(props.item.expires).getTime() < new Date().getTime()
                "
                :text="new Date(props.item.expires).toLocaleDateString('en-US')"
                style="color: red"
              />
              <ui-list-data
                v-else
                :text="
                  props.item.expires
                    ? new Date(props.item.expires).toLocaleDateString('en-US')
                    : '-'
                "
              />
              <ui-list-data small>
                <ui-link
                  type="danger"
                  uppercase
                  size="small"
                  @click="handleDeleteRegistraionLink(props.item.reference)"
                  >{{ $t("actions.delete") }}</ui-link
                >
                <ui-link
                  class="ml-2"
                  type="success"
                  uppercase
                  size="small"
                  @click="handleEditRegistraionLink(props.item)"
                  >{{ $t("actions.edit") }}</ui-link
                >
              </ui-list-data>
            </template>
          </ui-list>
        </ui-card>
      </el-tab-pane>
    </el-tabs>

    <!-- Modal for creating new faculty -->
    <create-faculty-modal
      v-if="selectedCompany"
      :show="newFacultyModal"
      :text="`${$t('actions.add')} ${$t('models.faculty')}`"
      :confirmText="$t('actions.add')"
      :company="selectedCompany"
      @confirm="handleNewFaculty"
      @cancel="newFacultyModal = false"
    >
    </create-faculty-modal>

    <!-- Modal for creating new faculty department -->
    <add-faculty-department-modal
      :show="newFacultyDepartmentModal"
      :text="`${$t('actions.add')} ${$t('models.faculty')}`"
      :confirmText="$t('actions.add')"
      :faculty="selectedFacultyObject"
      :optionalFaculties="faculties"
      @confirm="handleNewFacultyDepartment"
      @cancel="newFacultyDepartmentModal = false"
    >
    </add-faculty-department-modal>

    <ui-modal
      :show="showDeleteModal"
      :text="$t('links.actions.confirm_delete')"
      :info="$t('links.info.delete_link_info')"
      :confirmText="$t('actions.delete')"
      @confirm="deleteRegistrationLink()"
      @cancel="showDeleteModal = false"
    >
    </ui-modal>
  </div>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import UiModal from "@/components/ui/UiModal";
import UiList from "@/components/ui/UiList";
import UiListData from "@/components/ui/UiListData";
import FacultyConnections from "../components/faculty/FacultyConnections";
import StudentLookup from "../components/student/Lookup";
import FacultyDepartmentConnections from "../components/faculty/FacultyDepartmentConnections";
import UiLink from "../../../components/ui/UiLink";
import CreateFacultyModal from "../components/faculty/CreateFacultyModal";
import AddFacultyDepartmentModal from "../components/faculty/AddFacultyDepartmentModal";

import {
  getCompanies,
  getRegistrationLinks,
  deleteRegistrationLinkByReference,
} from "@/app/companies/api";
import {
  getCompanyFaculties,
  createFaculty,
  getFacultyDepartments,
  createFacultyDepartment,
} from "../api";

export default {
  name: "Index",

  components: {
    UiLink,
    UiCard,
    FacultyConnections,
    FacultyDepartmentConnections,
    CreateFacultyModal,
    AddFacultyDepartmentModal,
    StudentLookup,
    UiModal,
    UiList,
    UiListData,
  },

  data() {
    return {
      // Data
      companies: [],
      faculties: [],
      departments: [],

      // Conditionals
      facultiesLoading: false,
      departmentsLoading: false,

      // User selections
      selectedCompany: null,
      selectedFaculty: null,
      selectedFacultyObject: null,

      // Modal
      newFacultyModal: false,
      newFacultyDepartmentModal: false,
      showDeleteModal: false,
      markedRegistrationLink: null,

      registrationLinks: null,
    };
  },

  watch: {
    selectedCompany: async function () {
      this.facultiesLoading = true;
      this.faculties = await getCompanyFaculties(this.selectedCompany);
      this.facultiesLoading = false;
    },

    selectedFaculty: async function () {
      this.departmentsLoading = true;
      this.departments = await getFacultyDepartments(this.selectedFaculty);
      this.selectedFacultyObject = this.faculties.find(
        (f) => f.facultyId === this.selectedFaculty
      );
      this.departmentsLoading = false;
    },
  },

  methods: {
    // Faculty handlers

    async handleNewFaculty(facultyToCreate) {
      this.faculties = [
        ...this.faculties,
        await createFaculty(facultyToCreate),
      ];
      this.newFacultyModal = false;
    },

    async handleDeleteFaculty(facultyId) {
      this.facultiesLoading = true;
      this.faculties = [
        ...this.faculties.filter((f) => f.facultyId !== facultyId),
      ];
      this.facultiesLoading = false;
    },

    handleUpdateFaculty(updatedFaculty) {
      this.faculties = [
        ...this.faculties.filter(
          (f) => f.facultyId !== updatedFaculty.facultyId
        ),
        updatedFaculty,
      ];
    },

    // Faculty department

    async handleNewFacultyDepartment(facultyDepartmentToCreate) {
      this.departments = [
        ...this.departments,
        await createFacultyDepartment(facultyDepartmentToCreate),
      ];
      this.newFacultyDepartmentModal = false;
    },

    async handleDeleteFacultyDepartment(facultyDepartmentId) {
      this.departmentsLoading = true;
      this.departments = [
        ...this.departments.filter(
          (d) => d.facultyDepartmentId !== facultyDepartmentId
        ),
      ];
      this.departmentsLoading = false;
    },

    // Other
    async getCompanies() {
      this.loading = true;
      this.companies = await getCompanies();
      this.loading = false;
    },

    handleDeleteRegistraionLink(reference) {
      this.markedRegistrationLink = this.registrationLinks.find(
        (r) => r.reference === reference
      );
      this.showDeleteModal = true;
    },

    async deleteRegistrationLink() {
      deleteRegistrationLinkByReference(this.markedRegistrationLink.reference)
        .then(
          () =>
            (this.registrationLinks = this.registrationLinks.filter(
              (r) => r.reference !== this.markedRegistrationLink.reference
            ))
        )
        .then(() => (this.markedRegistrationLink = null))
        .finally(() => (this.showDeleteModal = false));
    },

    handleEditRegistraionLink(registrationLink) {
      const lang = this.$route.params.lang;
      this.$router.push(
        `/${lang}/app/companies/${registrationLink.companyId}/edit/link/${registrationLink.reference}`
      );
    },
  },

  async mounted() {
    this.getCompanies();
    this.registrationLinks = await getRegistrationLinks();
  },
};
</script>

<style scoped>
</style>
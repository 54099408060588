<template>
    <div v-loading="loading">
			<ui-card
					:heading="$t('misc.student_lookup')"
					class="mb-4"
			>
			
				<template #hright>
						<div class="flex flex-row justify-between">
							<el-input style="margin-right: 8px" v-model="sidNumberInput" type="text" :placeholder="$t('labels.sid_number')"></el-input>
							<ui-link type="success" @click="getStudentData">{{$t('labels.search')}}</ui-link>
					</div>
				</template>

				<template>
					<div v-if="student">

						<table>
							<tr>
									<th>Id</th>
									<td>{{student.id}}</td>
							</tr>
							<tr>
									<th>{{$tc('models.student', 1)}} Id</th>
									<td>{{student.studentId}}</td>
							</tr>
							<tr>
									<th>{{$tc('models.building', 1)}}</th>
									<td>{{student.building.name}}</td>
							</tr>

              <tr>
									<th>{{$t('labels.course.current')}}</th>
									<td>{{getTranslation(student.courses[0].title)}}</td>
							</tr>

              <tr>
									<th>{{$t('misc.started')}}</th>
									<td>{{parseDateOrDefault(student.courses[0].started, $t('misc.not_started'))}}</td>
							</tr>

              <tr>
									<th>{{$t('misc.completed')}}</th>
									<td>{{parseDateOrDefault(student.courses[0].completed, $t('misc.not_completed'))}}</td>
							</tr>

							<tr>
									<th>{{$t('misc.port_status')}}</th>
									<td>{{student.portStatus ? $t('misc.opened') : $t('misc.closed')}}</td>
							</tr>

							<tr>
									<th>{{$t('misc.registration')}}</th>
									<td>{{new Date(student.created).toLocaleString('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
												second: 'numeric',
											})}}
								</td>
							</tr>

							<tr>
									<th>{{$t('misc.registered_by')}}</th>
									<td>{{student.creator.firstName}} {{student.creator.lastName}} ({{student.creator.email}})</td>
							</tr>
						</table>

						<ui-link
							class="mt-2"
							type="success"
							size="small"
							uppercase
							@click="handleOpenPort"
						>
							{{ $t('misc.open_port') }}
						</ui-link>
					</div>
					<p v-else>{{$t('validation.no_info')}}</p>
				</template>

			</ui-card>

			<ui-card>
				<ui-list
						:disableXScroll="true"
						:heading="$t('labels.course.completions')"
						:headings="[$t('fields.name'), $t('misc.completed'), $t('misc.port_status')]"
						:items="student.history"
						v-if="student"
						:sort="[{ prop: 'title', type: 'translation' }]"
					>
						<template v-slot:default="props">
							
							<ui-list-data :text="getTranslation(props.item.course.title)"/>
							<ui-list-data :text="parseDateOrDefault(props.item.date, $t('misc.not_completed'))"/>
							<ui-list-data :text="props.item.portStatus ? $t('misc.opened') : $t('misc.closed')" />
						</template>
					</ui-list>
			</ui-card>
		</div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiList from '@/components/ui/UiList';
import UiLink from '@/components/ui/UiLink';
import UiListData from '@/components/ui/UiListData';

import { getStudentData, openPort } from '../../api';

export default {
    name: "StudentLookup",

    components: {
        UiCard,
				UiList,
				UiLink,
				UiListData
    },

    data: () => ({
        student: null,
				sidNumberInput: "",
        loading: false,
    }),

	methods: {
		async getStudentData() {
			var data = await getStudentData(this.sidNumberInput);

			this.student = data.student;
			this.student.courses = data.courses;
			this.student.building = data.building;
			this.student.creator = data.creator;
			this.student.history = data.history.filter(h => h.isCompleted);
		},

		getTranslation(transList) {
			return transList.filter(t => t.lang === this.$i18n.locale)[0].value;
		},

		parseDateOrDefault(dateString, def) {
			if (dateString === null || dateString === "") {
				return def;
			}

			return new Date(dateString).toLocaleString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			})
		},

		async handleOpenPort() {
      this.loading = true;
      try {
        await openPort(this.student);
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      } finally {
        this.loading = false;
			}

      this.getStudentData();
    },
	},
}
</script>

<style scoped>
	th {
		text-align: start;
		padding-right: 25px;
	}
</style>

<template>
  <div>
    <ui-list v-loading="loading"
             :headings="[this.$t('models.faculty'), this.$tc('models.building'), `${this.$tc('labels.count')} ${this.$tc('models.departments')}`, '']"
             :items="faculties"
             :sort="[
        { prop: 'building', prop2: 'name', type: 'level2' },
      ]"
    >
      <template v-slot:default="props">
        <ui-list-data :text="props.item.facultyName"></ui-list-data>
        <ui-list-data type="tag" :text="props.item.buildingName"></ui-list-data>
        <ui-list-data :text="`${props.item.facultyDepartmentCount} avdelinger`"></ui-list-data>

        <!-- Actions -->
        <ui-list-data small>
          <ui-link type="danger" uppercase size="small" @click="handleOpenDeleteFacultyModal(props.item)">{{$t('actions.delete')}}</ui-link>
          <ui-link class="ml-2" type="success" uppercase size="small" @click="handleOpenEditFacultyModal(props.item)">{{$t('actions.edit')}}</ui-link>
        </ui-list-data>
      </template>
    </ui-list>

    <!-- Modal displaying warning about deleting faculty -->
    <ui-modal :show="showDeleteFacultyModal" :text="$t('confirm.delete.faculty')" @confirm="handleDeleteFaculty" @cancel="handleCloseDeleteFacultyModal"/>

    <!-- Modal for editing faculty -->
    <edit-faculty-modal
        v-if="facultyToEdit"
        :show="facultyEditModal"
        :text="`${$t('actions.edit')} ${$t('models.faculty')}`"
        :confirmText="$t('actions.edit')"
        :faculty="facultyToEdit"
        @confirm="handleEditFaculty"
        @cancel="handleCloseEditFacultyModal"
    >
    </edit-faculty-modal>
  </div>
</template>


<script>
import UiLink from "../../../../components/ui/UiLink";
import UiList from "../../../../components/ui/UiList";
import UiModal from "../../../../components/ui/UiModal";
import UiListData from "../../../../components/ui/UiListData";
import EditFacultyModal from "./EditFacultyModal";
import {deleteFaculty, updateFaculty} from "../../api";

export default {
  name: "FacultyConnections",
  components: {
    UiLink,
    UiList,
    UiModal,
    UiListData,
    EditFacultyModal
  },

  props: {
    faculties: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
    }
  },

  data() {
    return {
      facultyToEdit: null,
      facultyEditModal: false,
      showDeleteFacultyModal: false,
      markedDelete: null,
    }
  },

  methods: {

    // Delete faculty
    handleOpenDeleteFacultyModal(faculty) {
      this.markedDelete = faculty;
      this.showDeleteFacultyModal = true;
    },

    handleCloseDeleteFacultyModal() {
      this.markedDelete = null;
      this.showDeleteFacultyModal = false;
    },

    async handleDeleteFaculty() {
      const facultyToDeleteId = this.markedDelete.facultyId;
      await deleteFaculty(facultyToDeleteId);
      this.showDeleteFacultyModal = false;
      this.$emit('onDelete', facultyToDeleteId);
    },

    handleOpenEditFacultyModal(faculty) {
      this.facultyToEdit = faculty;
      this.facultyEditModal = true;
    },

    handleCloseEditFacultyModal() {
      this.facultyToEdit = null;
      this.facultyEditModal = false;
    },

    async handleEditFaculty(facultyToUpdate) {
      const updatedFaculty = await updateFaculty(facultyToUpdate);
      this.$emit('onUpdate', updatedFaculty);
      this.facultyEditModal = false;
    }
  }
}
</script>

<style scoped>

</style>
